import { requests } from "../common/network";
import v from "../common/variable";
import u from "../user_need/user_need";

const { $dqs, debounce, setObserve, $dqsa } = require("../common/utils");
const { setInsertTarget, starEmbeddedAdaptation } = require("./page-common");

//Mark 获取收藏页产品A元素
function getProductsANodes() {
	// let collTarget = Review.collTarget
	const curCollArr = v.curCollArr;
	// let index = v.collTarInx
	// const start = index
	// index += 50
	// const end = index > collTarget.length ? collTarget.length : index

	let aNodeList = [];

	// collTarget = Array.from(collTarget)
	let hs = 'a[href*="/products/"]';
	let tarEle = "A";

	if (curCollArr.findIndex(item => item.tagName !== tarEle) === -1) {
		return curCollArr;
	} else if (curCollArr.findIndex(item => $dqs(hs, item) === null) === -1) {
		return curCollArr.map(item => $dqs(hs, item));
	}

	let curNode = curCollArr[0];
	let aNode;
	let newNodes = [];
	for (let i = 0; i < curCollArr.length; i++) {
		let targetNode = curCollArr[i];
		curNode = curCollArr[i];
		aNode = null;
		for (let j = 0; j < 5; j++) {
			curNode = curNode.parentNode;
			if (
				curNode.tagName === tarEle &&
				curNode.href &&
				curNode.href.indexOf("/products/") !== -1
			) {
				aNode = curNode;
				break;
			}
			let childA = $dqs(hs, curNode);
			if (childA) {
				aNode = childA;
				break;
			}
		}
		if (aNode) {
			aNodeList.push(aNode);
			newNodes.push(targetNode);
		}
	}
	v.curCollArr = newNodes;
	return aNodeList;
}
function getAClassName(node) {
	let aSelects;
	if (node.classList.value) {
		aSelects = "." + Array.from(node.classList).join(".");
	} else {
		let c = 5;
		while (c) {
			if (node.parentNode.classList.value) {
				aSelects =
					node.parentNode.tagName.toLowerCase() +
					"." +
					Array.from(node.parentNode.classList).join(".") +
					">a";
				break;
			}
			c--;
			node = node.parentNode;
		}
	}
	return aSelects;
}

// 防抖监听页面元素变动
const observeDebounce = debounce(() => {
	v.observer.disconnect();
	setCollectionObserve();
	let { collTarget } = Review;
	const oldStr = Array.from(collTarget)
		.map(item => item.textContent.replace(/\n/g, "").trim())
		.join();
	// const oldCount = collTarget.length
	getCollectionTarget();
	collTarget = Review.collTarget;
	const newStr = Array.from(collTarget)
		.map(item => item.textContent.replace(/\n/g, "").trim())
		.join();
	const collListLen = $dqsa(".collection-icon-list").length;

	if (
		((collListLen !== 0 && collListLen === collTarget.length) ||
			(collListLen === 0 &&
				v.userSetting.is_show_stars_without_review === 2)) &&
		oldStr === newStr
	) {
		setCollectionObserve();
	} else {
		Review.observeCount++;
		setInsertTarget(2);
		Review.collectionTarget = collTarget;
	}
}, 400);

//Mark 监听收藏页切换排序
function setCollectionObserve() {
	// 选择需要观察变动的节点
	const node = document.body;

	// 观察器的配置（需要观察什么变动）
	const config = { childList: true, subtree: true };
	// const config = { attributes: true, childList: true, subtree: true, characterData: true };
	// 当观察到变动时执行的回调函数
	const callback = function () {
		observeDebounce();
	};

	setObserve(node, config, callback, v);
}
//Mark 获取收藏页产品信息
function getProductHandleStrings(nodeList) {
	let productHandleStrings = "";
	if (nodeList.length) {
		let tarEle = "A";
		let handleArr = [];
		for (let i = 0; i < nodeList.length; i++) {
			if (
				nodeList[i] &&
				nodeList[i].tagName === tarEle &&
				nodeList[i].getAttribute("href")
			) {
				let href = nodeList[i].getAttribute("href");
				let start = href.lastIndexOf("/products/") + 10;
				let end = href.indexOf("?");
				let handle = "";
				if (end != -1) {
					handle = href.substring(start, end);
				} else {
					handle = href.substring(start);
				}
				if (handle.indexOf(".jpg") === -1) {
					handleArr.push(handle);
				}
			} else {
				handleArr.push("undefined");
			}
		}
		productHandleStrings = handleArr.join(",");
	}
	productHandleStrings = productHandleStrings.replace(/[#]/g, "");
	return productHandleStrings;
}

//Mark 获取收藏页嵌入目标
async function getCollectionTarget() {
	let collTarget = [],
		{ theme_store_id, isProPage, isOtherTheme } = v;
	let isAgainSearch = true;
	if (v.hasPyRating || v.hasEcomRt) {
		u.customCollTarSel =
			".pagefly-star-rating-sealapp,.custom-vstar-rating-widget,.ecom-star-rating-sealapp";
	}
	if (u.customCollTarSel) collTarget = $dqsa(u.customCollTarSel);

	if (!collTarget.length)
		switch (theme_store_id) {
			case 679: {
				//Supply
				collTarget = $dqsa("a.product-grid-item>p");
				break;
			}
			case 730: {
				//'Brooklyn'
				collTarget = $dqsa(".grid-product__title");
				break;
			}
			case 775: {
				//Venture
				collTarget = $dqsa(".product-card__name");
				break;
			}
			case 766: {
				//Boundless
				collTarget = $dqsa(".product-item__title");
				break;
			}
			case 887:
			case 1356:
			case 1363:
			case 1368:
			case 1567: {
				//Dawn Sense Crave Craft
				collTarget = $dqsa(".h5>.full-unstyled-link");
				if (collTarget.length === 0) {
					collTarget = $dqsa(".card-information__text");
				}
				isAgainSearch = false;
				break;
			}
			case 829: {
				//Narrative

				collTarget = $dqsa(".card__name,.spf-product-card__title");
				break;
			}
			case 578: {
				//Simple
				collTarget = $dqsa(".product__title a");
				break;
			}
			case 796: {
				//Debut
				// isOtherTheme= true
				collTarget = $dqsa(".product-card__title");
				break;
			}
			case 380: {
				//Minimal
				var itemList = $dqsa(".grid-link__container .grid__item");
				collTarget = [];
				itemList.forEach(item => {
					collTarget.push(item.querySelector(".grid-link__title"));
				});
				break;
			}
			case 885: {
				//Express
				isOtherTheme = true;
				collTarget = $dqsa(".product-card__title-wrapper");
				if (isProPage) {
					let node = $dqs(".shopify-section .product");
					node && (wrapWid = node.offsetWidth);
				}
				break;
			}
			default: {
				if (Review.theme && Review.theme.name === "warehouse") {
					// 解决warehouse主题列表类型星星评价太靠下问题
					const node = $dqs(".product-item__price-list.price-list");
					if (node && getComputedStyle(node).marginTop === "-15px") {
						v.globalStyle.textContent += `div.collection-icon-list.vstar-star {
            margin-bottom: 20px;
            }`;
					}
				}
				//Mark 其他主题适配
				isOtherTheme = true;
				collTarget = getOtherCollTar();
				break;
			}
		}
	if (!isOtherTheme) {
		if (collTarget.length === 0 && isAgainSearch)
			collTarget = getOtherCollTar();
	}

	if (v.isCollPage) {
		if (collTarget.length === 0 && $dqs('a[href*="/products"]')) {
			await requests
				.sendErrorMsg(
					2,
					`集合页评价嵌入失败，店铺地址为：${window.location.href}`
				)
				.then(res => {
					if (res[1]) {
						const selector = starEmbeddedAdaptation(4, res[1].selector);
						if (selector) {
							collTarget = $dqsa(selector);
						}
					}
				});
		}
	}

	if (collTarget.length !== 0) {
		collTarget = Array.from(collTarget);
		if (isProPage) {
			const proTarget = Review.proTarget;
			if (proTarget)
				collTarget = collTarget.filter(
					item => item !== proTarget && !proTarget.contains(item)
				);
		}
	}

	Review.collTarget = collTarget;
}

//Mark 收藏页嵌入目标类名分类
function getOtherCollTar() {
	let collTarget = [];
	const { isCollPage, isProPage, isHomePage, hasPyRating } = v;
	if (
		isCollPage ||
		isProPage ||
		isHomePage ||
		u.otherPageColl ||
		hasPyRating
		// || pageType==='product'
	) {
		let selector;
		if (u.customCollTarSel) {
			selector = u.customCollTarSel;
			collTarget = $dqsa(u.customCollTarSel);
		}
		// if (!u.isOpenCollTarMatch) return collTarget

		if (collTarget.length === 0) {
			selector = `.product-details>span.title,.apb_product_list_product_item_title,.grid_title,.collection__grid-item__details,
      .popup_cart_title>a,h3.product-title,a.ga-product_title,h4.title-product,.grid-item .inner-top>.product-bottom>a.product-title,
      .product__grid__title,.pt-title.prod-thumb-title-color,.product-item-meta__title,.product-grid-item__title,.product-item-title`;
			collTarget = $dqsa(selector);
			Review.collType = "type1";
		}

		if (collTarget.length === 0) {
			selector = `.product-bottom>.product-title,.product-details>.product-title,.product-item__details a,.info .title,.product-name>a,
  .collectionBlock__title>a,.product-grid-item>p,.innerer>.title,.grid__product-content>a,.ht-product-title>a,.h5>.full-unstyled-link,
  .product .card-title,.list_prod .title-product,.product.slick-slide .card-title,.product-title-price,.prd-title,.product-info>h3.title14,
  .list-view-item__title-column>a,.gt_product-name>h2,.block.text-base,.tt-description>h2.tt-title,.desc>h5,.product-item__title`;
			collTarget = $dqsa(selector);
			Review.collType = "type2";
		}

		if (collTarget.length === 0) {
			selector = `.ftr,.product-grid-desc>h3,.gt_product-name,p.list-product__title,.product--details-container,.product--item-title,.h4.grid-view-item__title,
  .product-grid-item__product-name,h3.card__name.h4,.grid-crop-title,.title-wrap,h3>span.overflowed,.product-grid--title>a,a.gf_product-title,.bls__product-details a,
  a>.indiv-product-title-text,h2.product_name,.sf__pcard-content .max-w-full,h2.product-card__title,.proName>a,a.item__name.pg__sync-url,.spf-product-card__title`;
			collTarget = $dqsa(selector);
			Review.collType = "type3";
		}

		if (collTarget.length === 0) {
			// collTarget = $dqsa('.product-title,.product__title,.grid-product__title,.product-block__title,.grid-view-item__title,.product-card__name,.ProductItem__Title,.product-item__title,.card-information__text')
			//.product-info>a>h2 57518784671  h4.product_name 57318408292
			selector = `.grid-product__title,.product-block__title,
  .grid-view-item__title,.product-card__name,.ProductItem__Title,.product-info>a>h2,
  .card-information__text,span.indiv-product-title-text,.card__title:not(.heading),h4.product_name,
  .productitem--title,.grid-link__title,.boost-pfs-filter-product-item-title,.product-collection__title,
  .col_product_text_color,.js-product-details-link,.t4s-product-title,.ws-card__title,.product-thumbnail__info>.product-thumbnail__header`;
			collTarget = $dqsa(selector);
			Review.collType = "type4";
		}

		if (collTarget.length === 0) {
			selector = `.card__heading[id^="title-template"],.lh-product-title`; //span.title,.product-title,.product__title
			collTarget = $dqsa(selector);
			Review.collType = "type5";
		}

		Review.coll_dqsa = "$dqsa(`" + selector + "`)";
		return collTarget;
	}
}

export {
	getProductsANodes,
	getAClassName,
	observeDebounce,
	setCollectionObserve,
	getProductHandleStrings,
	getCollectionTarget,
};
