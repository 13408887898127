import v from "../common/variable";
import { qaRequests, requests } from "../common/network";
import { getTotalStarString } from "../page/page-common";
import {
	$dqs,
	imgLoadLimit,
	scrollTo,
	$dqsa,
	gsSession,
	gsLocal,
} from "../common/utils";
import { UpdateGridColumnHeight, addGridNewCards, arrangeGrid } from "./grid";
import { sgPage } from "./review-pager";
import u from "../user_need/user_need";
import svg from "../common/svg";
import { setReviewFooter, setReviewPagination } from "./reviews-footer";

//Mark 构造评论列表
async function setReviewBody(isSort, isFilter) {
	v.couldPreLoading = false;
	v.imgCount = 0;
	v.loadedImgCount = 0;
	v.thumbImg = [];
	let revData,
		reviewsDom,
		revPageData = [];
	const { isMixReview, isWdoRevOpen, isList, userSetting, isRevPage } = v;
	let page = sgPage();
	const revPageType = userSetting.review_pagination_type;
	reviewsDom = isWdoRevOpen ? v.wdoRevDom : v.reviewsDom;
	const reviewsBody = isWdoRevOpen ? v.wdoRevBody : v.reviewsBody;
	const reviews = v.isWdoRevOpen ? v.wdoRev : v.reviews;

	const key = isWdoRevOpen ? "trustoo_all_reviews" : "trustoo_reviews";
	const isRemove = isSort || isFilter;
	if (isRemove) {
		revPageData = [];
		gsSession("set", key, []);
	} else {
		revPageData = gsSession("get", key);
	}

	const listWrapper = $dqs(".tt-review-list", reviewsBody);
	if (
		(!isRevPage && !isWdoRevOpen && (revPageType === 1 || isRemove)) ||
		(isRemove && (isMixReview || isWdoRevOpen))
	) {
		listWrapper.style.height = "auto";
		toggleLoading(listWrapper, reviewsDom, true);

		reviews.cardCount = 0;
		if (v.isMobile && isWdoRevOpen) {
			scrollTo(reviewsDom);
		}
		if (!v.isFirstPageRender) {
			if (v.isList) {
				scrollTo(reviewsDom);
			} else {
				scrollTo(reviewsDom, "auto");
			}
		}
	}

	const isFirstPageType = revPageType === 1;
	if (isFirstPageType && revPageData[page - 1]) {
		getReviewsBodyItem(revPageData[page - 1]);
	} else {
		const sortType = reviewsDom.getAttribute("review_sort_by");
		if (
			typeof vstar_product_reviews !== "undefined" &&
			!v.isReviewsUpdate &&
			page === 1 &&
			!isWdoRevOpen &&
			!isRemove &&
			!isMixReview &&
			userSetting.auto_switch_language !== 1
		) {
			revData = vstar_product_reviews;
		} else {
			const res = await requests.getProRevList(sortType, reviews.ratingFilter);
			revData = res[1];
		}

		const pageCount = revData.page.total_page;

		if (!reviewsDom.getAttribute("review-page-count")) {
			if (v.isMixReview || v.isWdoRevOpen) {
				v.allRatingData = revData.total_rating;
				if (v.allRatingData === null) {
					reviewsDom.innerHTML = "";
				}
			}
			setReviewFooter(reviewsDom, pageCount);
		}
		reviewsDom.setAttribute("review-page-count", pageCount);
		if (isMixReview || isWdoRevOpen) {
			if (revData.page.count === 0) {
				reviewsDom.parentNode.style.display = "block";
				reviewsDom.insertAdjacentHTML(
					"beforeend",
					`<div id="reviews-head" style="border: none;"></div><div class="no-review-tip">There are no reviews yet</div>`
				);
				return;
			} else {
				const tip = $dqs(".no-review-tip", reviewsDom);
				if (tip) {
					tip.remove();
				}
			}
		}

		if (!v.isWebPSupported) {
			revData.list.forEach(it => {
				if (it.resources.length !== 0) {
					it.resources.forEach(it => {
						it.src = it.src.replace("trustoo_big", "q_90");
						it.thumb_src = it.thumb_src.replace("trustoo_small", "w_220");
					});
				}
			});
		}
		const revDataList = revData.list;
		revPageData[page - 1] = revDataList;
		gsSession("set", key, revPageData);

		if (isRemove && revPageType === 1) {
			v.pager && v.pager.pageClick(null, 1);
		}

		if (isList) {
			getReviewsBodyItem(revDataList, isRemove, isFilter);
		} else {
			getReviewsBodyItem(revDataList, isRemove, isFilter);
		}
	}
}

//Mark构造每条评论
function getReviewsBodyItem(dataList, isRemove, isFilter) {
	const { userSetting, isRevPage, isWdoRevOpen, isGrid, isSplit, globalStyle } =
		v;
	const { isShowRevRatingNum, isImgLoadLazy } = u;

	const reviews = isWdoRevOpen ? v.wdoRev : v.reviews;
	const reviewsBody = isWdoRevOpen ? v.wdoRevBody : v.reviewsBody;
	const reviewsDom = reviewsBody.parentNode;
	// const bodyMask = $dqs('.trustoo-body-mask', reviewsBody)

	let curCards = [];

	const revPageType = userSetting.review_pagination_type;
	const page = sgPage();
	const listWrapper = $dqs(".tt-review-list", reviewsBody);

	if (isShowRevRatingNum && sgPage() === 1) {
		globalStyle.innerHTML += `#vstar-reviews .star{display:flex;align-items:center}
                #vstar-reviews .rev-rating-num{font-size:15px;margin-left:8px}`;
	}
	let imgAttr = "";
	if (isImgLoadLazy) {
		imgAttr = 'loading="lazy"';
	}

	let styleText = "";
	if (isGrid) {
		styleText = 'style="visibility:hidden"';
	}

	const startCardNo = reviews.cardCount;
	let cardCount = reviews.cardCount;
	let extentCls = "";
	if (isSplit) {
		extentCls = " split";
	}
	dataList.forEach(item => {
		let noText = "";
		noText = `no="${++cardCount}"`;

		Object.assign(item, { imgAttr, cardCount, noText, styleText });

		const reviewNode = document.createElement("div");
		reviewNode.className = v.layoutClass + "review" + extentCls;
		reviewNode.innerHTML = getEachReviewHtml(item);
		// 添加评论
		listWrapper.appendChild(reviewNode);
		reviewNode.setAttribute("no", cardCount);
		reviewNode.setAttribute("review-id", item.id);
		curCards.push(reviewNode);
		if (isGrid) {
		} else if (v.isList && !u.isNoEllipsisReviews) {
			const node = $dqs(".reviews-text", reviewNode);
			multiLineEllipsis(node, item.content, 3);
		}
	});
	reviews.cardCount = cardCount;
	curCards.forEach(i => {
		let imgs = [];
		// if (v.isList) {
		// 	imgs = $dqsa(".resource-item>img", i);
		// } else if (v.isGrid) {
		// 	if ($dqs(".resource-item>img", i)) {
		// 		imgs.push($dqs(".resource-item>img", i));
		// 	}
		// }
		imgs = $dqsa(".resource-item>img", i);
		if (imgs.length !== 0) {
			if (v.isGrid) {
				v.gridShowImgCount++;
			}
			imgs.forEach(i => {
				i.onload = function () {
					if (!i.getAttribute("loaded")) {
						i.setAttribute("loaded", true);
						loadReviewsImgs("success", this);
					}
				};

				i.onerror = function () {
					if (!i.getAttribute("loaded")) {
						i.setAttribute("loaded", true);
						const resList = i.closest(".resource-list");
						if (v.isGrid) {
							const multiIcon = $dqs(
								".multi-photo-wrapper",
								resList.parentNode
							);
							if (i.getAttribute("alt") == 0) {
								resList.remove();
								multiIcon && multiIcon.remove();
							}
						} else if (v.isList) {
							i.parentNode.remove();
							if (resList.children.length === 0) {
								resList.remove();
							}
						}

						loadReviewsImgs("fail", this);
					}
				};
				i.src = i.getAttribute("data-original");
			});
		}
	});

	if (page === 1 && (v.isFirstPageRender || isWdoRevOpen || isRevPage)) {
		if (isGrid) {
			addGridNewCards(curCards, startCardNo, isRemove);
		}
	} else {
		if (isGrid) {
			let isInitHigh = false;
			if (!isWdoRevOpen && !isRevPage) {
				isInitHigh = revPageType === 1 || isRemove;
			}
			addGridNewCards(curCards, startCardNo, isInitHigh);
		}
	}
	setReviewPagination(revPageType, reviewsDom, page, isFilter);
	toggleLoading(listWrapper, reviewsDom, false);

	if (v.isList) {
		imgLoadLimit(v.origImg, 5);
	}
}

//Mark 构造列表评论图片
function getReviewImage(image, imgAttr, noText, index, videoTipHtml) {
	const { origImg, thumbImg } = v;
	let listImg = "";

	listImg += `<div class="resource-item"><img index="${index}" alt="${index}" ${noText} "
     ${imgAttr} data-original="${image.thumb_src}" src="${image.thumb_src}" >
      ${videoTipHtml}
     </div>`;

	v.imgCount++;
	thumbImg.push(image.thumb_src);
	origImg.push(image.src);

	return listImg;
}

function loadReviewsImgs(status, img) {
	const no = img.getAttribute("no");
	const index = img.getAttribute("index");
	// if (status === 'success') {
	// } else
	if (status === "fail") {
		if (v.isGrid) {
			img.src = "https://cdn.vstar.app/static/images/default.png";
			img.setAttribute(
				"data-original",
				"https://cdn.vstar.app/static/images/default.png"
			);
			img.parentNode.style.height = img.parentNode.style.width;
			const columnNum = img.parentNode.parentNode.className.slice(-1);
			UpdateGridColumnHeight(columnNum, no);
			Review.loadedPhoto.push(no);
		} else if (v.isList) {
			// img.parentNode.style.display = 'none'
		}
	}

	if (v.isGrid && index === "0") {
		v.gridLoadedShowImg++;
		if (v.gridLoadedShowImg === v.gridShowImgCount) {
			arrangeGrid();
			if (!v.isImgLoadLazy) {
				imgLoadLimit(v.origImg, 5);
			}
		}
	}
}

// Mark 获取每条评论的html
function getEachReviewHtml(data) {
	const {
		isMobile,
		layoutClass,
		userSetting,
		origImg,
		thumbImg,
		isGrid,
		isList,
		isSplit,
		lang,
	} = v;
	const { isShowRevVerified, isAddRevVrfy, isShowRevRatingNum } = u;

	const { noText, imgAttr } = data;

	let listMobileStar = "",
		gridMobileCountry = "",
		elseMobileCountry = "",
		gridDate = "",
		revRatingNum = "",
		userVerified = "",
		itemType = "",
		merchantReply = "",
		relatedPro = "",
		gridImg = "",
		listImg = "",
		cntHtml = "",
		helpfulHtml = "",
		avatar = "";
	const isAvatar = v.userSetting.customer_avatar === 1;
	const starTag = `<div class="star vstar-star"> ${getTotalStarString(
		data.star
	)} <span class="rev-rating-num" > ${revRatingNum} </span> </div>`;

	// 视频图片资源
	data.resources.forEach((it, index) => {
		let videoTipHtml =
			it.resource_type === 2
				? `<div class="tt-video-player">
      ${svg.video}
    </div>`
				: "";
		let src = "";
		if (it.resource_type === 1) {
			src = it.src;
		} else if (it.resource_type === 2) {
			src = it.thumb_src;
		}
		if (isGrid) {
			const proportion = (it.width / it.height).toFixed(2);
			if (index == 0) {
				let imgSty = "";
				if (it.average_hue !== "") {
					imgSty = `background-color:${it.average_hue}`;
				}
				gridImg += `<div class="resource-item" style="${imgSty}"  
				new-grid-img proportion="${proportion}">
          <img index="${index}" alt="${index}" ${noText} ${imgAttr} 
					data-original="${src}">
          ${videoTipHtml}
        </div>`;
				v.imgCount++;
				thumbImg.push(it.thumb_src);
			} else {
				gridImg += `<div class="resource-item" proportion="${proportion}">
				<img index="${index}" alt="${index}"
           data-original="${it.src}" src="${it.thumb_src}" ></div>`;
			}
			origImg.push(it.src);
		} else if (isList) {
			listImg += getReviewImage(it, imgAttr, noText, index, videoTipHtml);
		}
	});
	if (data.resources.length !== 0) {
		let multiPhotoTip = "";
		if (data.resources.length > 1) {
			multiPhotoTip = `<div class="multi-photo-wrapper">${svg.multiPhoto()}</div>`;
		}
		if (isGrid) {
			gridImg = `${multiPhotoTip}<div class="resource-list">${gridImg}</div>`;
		} else if (isList) {
			listImg = `<div class="resource-list">${listImg}</div>`;
		}
	}

	if (isShowRevRatingNum) {
		revRatingNum = data.star;
	}

	// 验证徽章
	const veryIcon = v.badgeIconSvg;
	const veryText = `<span class="verified-text">${lang.verified_purchase}</span>`;
	const veryType = userSetting.is_show_verified_badge;
	let onlyVeryIcon = "";
	if (isAddRevVrfy) data.verified_badge = 1;
	if (isShowRevVerified && data.verified_badge === 1) {
		if (veryType === 1) {
			const icon = isAvatar ? "" : veryIcon;
			userVerified = `<div class="user-verified${
				v.badgeIconSvg ? "" : " pending"
			}">
      ${icon}${veryText}</div>
    `;
		} else if (veryType === 2) {
		} else if (veryType === 3 && !isAvatar) {
			onlyVeryIcon = veryIcon;
		}
	}

	// 日期显示
	let reviewDate = "";
	const timeAgoType = 5;
	if (userSetting.review_date_format_type === timeAgoType) {
		reviewDate = getTimeAgo(data.commented_at);
	} else {
		reviewDate = dateFormat(
			data.commented_at,
			userSetting.review_date_format_type
		);
	}
	const dateHtml = reviewDate
		? `<span class="reviews-date">${reviewDate}</span>`
		: "";

	if (isGrid) {
		if (isMobile) {
			gridDate = dateHtml;
		}
	}

	// 头像显示
	if (isAvatar) {
		const icon =
			[1, 3].includes(userSetting.is_show_verified_badge) &&
			data.verified_badge === 1
				? veryIcon
				: "";
		const name = data.author.trim()
			? data.author.split(" ").reduce((pre, it) => pre + it.charAt(0), "")
			: "A";

		avatar = `<div class="tt-review-avatar">${name.slice(0, 2)}${icon}</div>`;
	}

	// 国旗国家显示
	var user_country = "";
	const flagCls = data.author_country
		? "country-flag " + data.author_country
		: "";
	const flag = `<span class="${flagCls}"
   style="${data.author_country ? "border: 1px solid #ccc;" : ""}" ></span>`;
	const country = ` <span class="country-name">${data.author_country}</span>`;
	const flagType = userSetting.is_show_country_flag;

	if (flagType == 1) {
		user_country = flag + country;
	} else if (flagType == 3) {
		user_country = flag;
	} else if (flagType == 4) {
		user_country = country;
	}

	if (isMobile && isGrid) gridMobileCountry = user_country;
	else elseMobileCountry = user_country;

	if (u.isNoEllipsisReviews) {
		cntHtml = data.content;
	} else {
		if (isGrid) {
			if (
				(data.content.length > 200 && !isMobile) ||
				(data.content.length > 100 && isMobile)
			) {
				cntHtml = wordCountEllipsis(data.content, data.cardCount);
			} else {
				cntHtml = data.content;
			}
		} else if (isList) {
			cntHtml = "";
		}
	}
	// 变体
	if (v.userSetting.item_type === 1 && data.item_type) {
		itemType = `<div class="tt-item-type" style="margin-top: 12px">
			<p style="font-size: 12px; opacity: 0.6">
				${lang.item_type}
			</p>
			<p style="font-size: 14px;">${data.item_type}</p>
		</div>`;
	}
	// 评论回复
	const avatarSrc = v.userSetting.store_avatar_src;
	if (data.reply_content !== "") {
		const content = v.lang.shop_name.replace(
			"{{shop_name}}",
			v.userSetting.store_name
		);
		merchantReply = `<div class="merchant-reply">
          <div style="display:flex;align-items: center; margin-bottom:12px">
						${
							avatarSrc
								? `<div class="tt-shop-avatar"><img src="${avatarSrc}" /></div>`
								: ""
						}
						<div style="font-size: 14px;font-weight: 700;">${content}</div>
					</div>
          <div class="reply-content">${data.reply_content}</div>
        </div>`;
	}

	// 赞踩
	if (v.userSetting.is_show_helpful === 1) {
		const LocalKey = "tt_review_" + v.shop_id;
		let records = gsLocal("get", LocalKey);
		let helpfulCls = "",
			unhelpfulCls = "";
		if (records) {
			records = JSON.parse(records);
			const active = records.find(it => it.id === data.id);
			if (active) {
				if (active.likeType === 1) {
					helpfulCls = "active";
				} else if (active.likeType === 2) {
					unhelpfulCls = "active";
				}
			}
		}
		helpfulHtml = `<div class="review-helpful"><span>${lang.helpful}</span>
		${svg.helpful(helpfulCls)}<span class="tt-helpful-count">${
			data.likes_count
		}</span>
		${svg.unhelpful(unhelpfulCls)}<span class="tt-unhelpful-count">${
			data.dislike_count
		}</span></div>`;
	}

	// 全部评论区的关联产品
	if (data.corresponding_product) {
		const proInfo = data.corresponding_product;
		let proName = proInfo.product_name;
		if (proName.length > 50) {
			proName = proName.substr(0, 50) + "...";
		}
		relatedPro = `
        <a class="related-product" url="${proInfo.product_url}" href="${proInfo.product_url}" target="_blank">
          <div class="product-image"><div style="background-image:url(${proInfo.product_image})" class="related-product-image"></div></div>
          <div class="product-name">${proName}</div>
        </a>
      `;
	}

	let splitStars = "",
		normalStars = "",
		splitDate = "",
		normalDate = "";
	if (isSplit) {
		splitStars = starTag;
		splitDate = dateHtml;
	} else {
		normalStars = starTag;
		normalDate = dateHtml;
	}

	const reviewTitle =
		data.title && userSetting.is_show_review_title == 1
			? `<div class="tt-review-title">${data.title}</div>`
			: "";

	const reviewsItem = `
                  ${gridImg}                  
          <div class="${layoutClass}review-head">
          <div class="review-row-one" >
            ${normalStars}${normalDate}
          </div>
            <div class="grid-mobile"> ${gridDate + gridMobileCountry}</div>
            <div class="user-message">
						${avatar}
						<div>
              <span class="user-name"> <span class="author-name"> ${
								data.author
							}  </span> </span>
              ${onlyVeryIcon}
              ${elseMobileCountry}    
              ${userVerified}                                  
              ${listMobileStar}
							</div>
            </div>
						${splitDate}
          </div>
          <div class="${layoutClass}review-body">
					${splitStars}
					${reviewTitle}
					<p class="reviews-text">
							${cntHtml}
					</p>
          ${listImg}
          ${itemType}
          ${merchantReply}
					${helpfulHtml}
          ${relatedPro}

          </div>
   `;

	return reviewsItem;
}

function dateFormat(dateTime, type) {
	const date = dateTime.split(" ")[0];
	let d = [];
	if (date.indexOf("/") !== -1) {
		d = date.split("/");
	} else if (date.indexOf("-") !== -1) {
		d = date.split("-");
	}
	let formatDate = "";
	const mmddyyyy = 1,
		ddmmyyyy = 2,
		yyyymmdd = 3;
	if (type === mmddyyyy) {
		formatDate = d[1] + "/" + d[2] + "/" + d[0];
	} else if (type === ddmmyyyy) {
		formatDate = d[2] + "/" + d[1] + "/" + d[0];
	} else if (type === yyyymmdd) {
		formatDate = date;
	}
	return formatDate;
}

// Mark 赞踩评论
function checkHelpful(target, tarCls) {
	if (v.likeRevLock) {
		return;
	}
	let type = "";
	let request = null;
	if (v.showType === 1) {
		type = "review";
		request = requests.likeOrDislikeReview;
	} else if (v.showType === 2) {
		type = "qa";
		request = qaRequests.likeOrDislikeQA;
	}
	v.likeRevLock = true;
	let status = 2;
	let likeType = 1;
	if (tarCls.contains("tt-helpful")) {
		likeType = 1;
	} else if (tarCls.contains("tt-unhelpful")) {
		likeType = 2;
	}
	const reviewId = target.closest(`[${type}-id]`).getAttribute(`${type}-id`);
	const tarAct = tarCls.contains("active");
	const parentNode = target.parentNode;

	const hc = $dqs(".tt-helpful-count", parentNode);
	const uhc = $dqs(".tt-unhelpful-count", parentNode);

	const LocalKey = "tt_" + type + "_" + v.shop_id;
	let records = gsLocal("get", LocalKey);
	if (!records) {
		records = [];
	} else {
		records = JSON.parse(records);
	}
	const recordsInx = records.findIndex(it => it.id === reviewId);
	const nextNode = target.nextElementSibling;
	if (tarAct) {
		status = 2;
	} else {
		status = 1;
	}
	if (!tarAct) {
		if (recordsInx !== -1) {
			status = 3;
		}
	}
	request(likeType, status, reviewId).then(res => {
		if (res[0]) {
			v.likeRevLock = false;
			return;
		}
		const active = $dqs(".active", parentNode);
		if (active) {
			active.classList.remove("active");
		}
		// gsLocal("set");
		if (tarAct) {
			target.classList.remove("active");
			if (recordsInx !== -1) {
				records.splice(recordsInx, 1);
			}
			nextNode.textContent = parseInt(nextNode.textContent) - 1;
		} else {
			if (recordsInx !== -1) {
				records.splice(recordsInx, 1);
			}
			target.classList.add("active");
			records.push({ id: reviewId, likeType });
			nextNode.textContent = parseInt(nextNode.textContent) + 1;
		}
		if (status === 3) {
			if (likeType === 1) {
				uhc.textContent = parseInt(uhc.textContent) - 1;
			} else if (likeType === 2) {
				hc.textContent = parseInt(hc.textContent) - 1;
			}
		}
		gsLocal("set", LocalKey, JSON.stringify(records));
		setTimeout(() => {
			v.likeRevLock = false;
		}, 300);
	});
}

function wordCountEllipsis(str, cardCount) {
	let txtLen = 200;
	if (v.isMobile) {
		txtLen = 100;
	}
	txtLen -= v.lang.show_full_review.length;
	let cardId = cardCount ? `cardNo="${cardCount}"` : "";
	return `${str.substr(0, txtLen)}<span class="display-text" ${cardId} >...${
		v.lang.show_full_review
	}</span><span hidden class="remain-text">${str.substr(txtLen)}</span>`;
}

// 评论内容溢出隐藏处理
function ellipsisTextDisplay(node) {
	var parent = node.parentNode;
	parent.querySelector(".remain-text").removeAttribute("hidden");
	const index = node.getAttribute("index");
	const no = node.getAttribute("cardno");
	node.style.display = "none";
	if (v.isGrid) UpdateGridColumnHeight(index, no);
}

//Mark 多行省略
async function multiLineEllipsis(node, str, reviewsRow, cardCount) {
	var lineHeight = v.userSetting.font_size * 1.4;
	var at = reviewsRow * lineHeight;
	await new Promise(res => {
		setTimeout(() => res(), 200);
	});
	var tempstr = str;
	node.innerHTML = tempstr;
	var len = tempstr.length;
	let txt = "";
	if (node.offsetHeight <= at) {
		//如果所有文本在写入html后文本没有溢出，那不需要做溢出处理/*node.innerHTML = tempstr;*/
	} else {
		//否则 二分处理需要截断的文本
		var low = 0;
		var high = len;
		var middle;
		while (node.offsetHeight > at) {
			middle = (low + high) / 2;
			node.innerHTML = tempstr.substring(0, middle); //写入二分之一的文本内容看是否需要做溢出处理
			if (node.offsetHeight <= at) {
				//不需要 则写入全部内容看是否需要 修改low的值
				node.innerHTML = tempstr.substring(0, high);
				low = middle;
			} else {
				//写入二分之一的文本内容依旧需要做溢出处理 再对这二分之一的内容的二分之一的部分做判断
				high = middle - 1; //修改high值            }
			}
		}
		//减10个字符是防止最后一行溢出
		txt = tempstr.substring(0, high - v.lang.show_full_review.length - 10);
		node.innerHTML = txt;
	}
	if (txt) {
		let cardId = cardCount ? `cardId="${cardCount}"` : "";
		let html = `<span class="display-text" ${cardId} >...${
			v.lang.show_full_review
		}</span><span hidden class="remain-text">${str.substr(txt.length)}</span>`;
		node.insertAdjacentHTML("beforeend", html);
	}
}

function toggleLoading(listWrapper, reviews, isLoading) {
	const loading = $dqs("#tt-reviews-loading", listWrapper);
	const pager = $dqs(".page-control[type=reviews]", reviews);
	const loadingHtml = `<div id="tt-reviews-loading">
	<div class="tt-loading-1 tt-loading-item"></div>
	<div class="tt-loading-2 tt-loading-item"></div>
	<div class="tt-loading-3 tt-loading-item"></div>
</div>`;
	if (isLoading) {
		!loading && (listWrapper.innerHTML = loadingHtml);
		pager && (pager.style.display = "none");
	} else {
		loading && loading.remove();
		pager && (pager.style.display = "flex");
	}
}

function getTimeAgo(timeString) {
	// 将时间字符串转换为 Date 对象
	const date = new Date(timeString + "Z");
	const timestamp = date.getTime(); // 获取毫秒时间戳

	const now = new Date().getTime();
	const diffInSeconds = Math.floor((now - timestamp) / 1000); // 时间差（秒）

	const secondsInMinute = 60;
	const secondsInHour = 60 * secondsInMinute;
	const secondsInDay = 24 * secondsInHour;
	const secondsInMonth = 30 * secondsInDay;
	const secondsInYear = 365 * secondsInDay;
	const texts = v.lang.time_ago;
	let number = "",
		field = "just";
	// 判断时间差范围并返回对应的字符串
	if (diffInSeconds < secondsInMinute) {
		field = "just";
	} else if (diffInSeconds < secondsInHour) {
		number = Math.floor(diffInSeconds / secondsInMinute);
		// field = number === 1 ? "minute" : "minutes";
		field = "minute";
		// return texts[field].replace("{{time_number}}", number);
	} else if (diffInSeconds < secondsInDay) {
		number = Math.floor(diffInSeconds / secondsInHour);
		// field = number === 1 ? "hour" : "hours";
		field = "hour";
		// return texts.hours.replace("{{time_number}}", number);
	} else if (diffInSeconds < secondsInMonth) {
		number = Math.floor(diffInSeconds / secondsInDay);
		// field = number === 1 ? "day" : "days";
		field = "day";
		// return texts.days.replace("{{time_number}}", number);
	} else if (diffInSeconds < secondsInYear) {
		number = Math.floor(diffInSeconds / secondsInMonth);
		// field = number === 1 ? "month" : "months";
		field = "month";
		// return texts.months.replace("{{time_number}}", number);
	} else {
		number = Math.floor(diffInSeconds / secondsInYear);
		// field = number === 1 ? "year" : "years";
		field = "year";
		// return texts.years.replace("{{time_number}}", number);
	}
	if (number !== 1 && field !== "just") {
		field += "s";
	}
	return texts[field].replace("{{time_number}}", number);
}

export {
	setReviewBody,
	ellipsisTextDisplay,
	checkHelpful,
	dateFormat,
	getTimeAgo,
};
